import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './CardHistory.style.css'

const CardHistory = ({

    day,
    dateAndMonth,

    exteriorVehicleWashing,
    internalVehicleWashing,

    exteriorVehicleWashingCar,
    exteriorVehicleWashingVan,
    exteriorVehicleWashingTruck,

    internalVehicleWashingCar,
    internalVehicleWashingVan,
    internalVehicleWashingTruck,

    exteriorVehicleWashingCash,
    exteriorVehicleWashingSupercard,
    exteriorVehicleWashingGyral,
    exteriorVehicleWashingTotal,

    internalVehicleWashingCash,
    internalVehicleWashingSupercard,
    internalVehicleWashingGyral,
    internalVehicleWashingTotal,

    checkoutCondition,
    checkoutCharged,

    terminalCondition,
    terminalCharged,

    terminalStateCash,
    terminalStateNumber
}) => {

    const [open, setOpen] = useState(false)

    return (
        <>
            <div className="card-history" onClick={() => setOpen(!open)}>
                <Container fluid>
                    <Row>

                        <Col xs={12} sm={12} md={4} lg={4}>
                            <div className='card-history__group'>
                                <div className='card-history__label-two card-history__color-two'>{day}</div>
                                <div className='card-history__label-one card-history__color-one'>{dateAndMonth}</div>
                            </div>
                        </Col>

                        <Col xs={6} sm={6} md={4} lg={4}>
                            <div className='card-history__group'>
                                <div className='card-history__label-two card-history__color-one'>Vanjsko</div>
                                <div className='card-history__label-one card-history__color-three'>{exteriorVehicleWashing ? exteriorVehicleWashing : '-'}</div>
                            </div>
                        </Col>

                        <Col xs={6} sm={6} md={4} lg={4}>
                            <div className='card-history__group'>
                                <div className='card-history__label-two card-history__color-one'>Unutrašnje</div>
                                <div className='card-history__label-one card-history__color-three'>{internalVehicleWashing ? internalVehicleWashing : '-'}</div>
                            </div>
                        </Col>

                        {open &&
                            <>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className="card-history__border"></div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-one'>Broj pranja (vanjsko)</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>AUTO/SUV/VAN</div>
                                        <div className='card-history__label-three card-history__color-one'>{exteriorVehicleWashingCar ? exteriorVehicleWashingCar : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>KOMBI</div>
                                        <div className='card-history__label-three card-history__color-one'>{exteriorVehicleWashingVan ? exteriorVehicleWashingVan : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>KAMION</div>
                                        <div className='card-history__label-three card-history__color-one'>{exteriorVehicleWashingTruck ? exteriorVehicleWashingTruck : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className="card-history__border"></div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-one'>Broj pranja (unutrašnje)</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>AUTO/SUV/VAN</div>
                                        <div className='card-history__label-three card-history__color-one'>{internalVehicleWashingCar ? internalVehicleWashingCar : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>KOMBI</div>
                                        <div className='card-history__label-three card-history__color-one'>{internalVehicleWashingVan ? internalVehicleWashingVan : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>KAMION</div>
                                        <div className='card-history__label-three card-history__color-one'>{internalVehicleWashingTruck ? internalVehicleWashingTruck : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className="card-history__border"></div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-one'>Prihodi (vanjsko)</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Gotovina</div>
                                        <div className='card-history__label-three card-history__color-one'>{exteriorVehicleWashingCash ? `${exteriorVehicleWashingCash}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Superkartica</div>
                                        <div className='card-history__label-three card-history__color-one'>{exteriorVehicleWashingSupercard ? `${exteriorVehicleWashingSupercard}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Žiralno</div>
                                        <div className='card-history__label-three card-history__color-one'>{exteriorVehicleWashingGyral ? `${exteriorVehicleWashingGyral}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Total</div>
                                        <div className='card-history__label-three card-history__color-one'>{exteriorVehicleWashingTotal ? `${exteriorVehicleWashingTotal}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className="card-history__border"></div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-one'>Prihodi (unutrašnje)</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Gotovina</div>
                                        <div className='card-history__label-three card-history__color-one'>{internalVehicleWashingCash ? `${internalVehicleWashingCash}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Superkartica</div>
                                        <div className='card-history__label-three card-history__color-one'>{internalVehicleWashingSupercard ? `${internalVehicleWashingSupercard}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Žiralno</div>
                                        <div className='card-history__label-three card-history__color-one'>{internalVehicleWashingGyral ? `${internalVehicleWashingGyral}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Total</div>
                                        <div className='card-history__label-three card-history__color-one'>{internalVehicleWashingTotal ? `${internalVehicleWashingTotal}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className="card-history__border"></div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-one'>Kasa</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Stanje kase</div>
                                        <div className='card-history__label-three card-history__color-one'>{checkoutCondition ? `${checkoutCondition}KM - naplaćeno ${checkoutCharged}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className="card-history__border"></div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-one'>Terminal</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Stanje terminala</div>
                                        <div className='card-history__label-three card-history__color-one'>{terminalCondition ? `${terminalCondition}KM - naplaćeno ${terminalCharged}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className="card-history__border"></div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-one'>Stanje naplatnih stanica</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Total</div>
                                        <div className='card-history__label-three card-history__color-one'>{terminalStateCash ? `${terminalStateCash}KM` : '-'}</div>
                                    </div>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <div className='card-history__group'>
                                        <div className='card-history__label-two card-history__color-two'>Broj pranja</div>
                                        <div className='card-history__label-three card-history__color-one'>{terminalStateNumber ? `${terminalStateNumber}` : '-'}</div>
                                    </div>
                                </Col>

                            </>
                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CardHistory
