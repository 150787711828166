import React from 'react'

import './Layout.style.css'

const Layout = ({ search, normal, none, children }) => {

    return (
        <>
            <div className={`${search && 'layout-search'} ${normal && 'layout-normal'} ${none && 'layout-none'}`}>
                {children}
            </div>
        </>
    )
}

export default Layout