export const valueSearchSelector = state => state?.searchReducer?.value
export const resultsSearchSelector = state => state?.searchReducer?.results

export const _idOrderSelector = state => state?.orderReducer?._id
export const amountTotalOrderSelector = state => state?.orderReducer?.amountTotal
export const amountExteriorVehicleWashingOrderSelector = state => state?.orderReducer?.amountExteriorVehicleWashing
export const amountInternalVehicleWashingOrderSelector = state => state?.orderReducer?.amountInternalVehicleWashing
export const donePersonOrderSelector = state => state?.orderReducer?.donePerson
export const doneTimeOrderSelector = state => state?.orderReducer?.doneTime
export const enterPersonOrderSelector = state => state?.orderReducer?.enterPerson
export const enterTimeOrderSelector = state => state?.orderReducer?.enterTime
export const exteriorVehicleWashingPaidOrderSelector = state => state?.orderReducer?.exteriorVehicleWashingPaid
export const exteriorVehicleWashingOrderSelector = state => state?.orderReducer?.exteriorVehicleWashing
export const internalVehicleWashingOrderSelector = state => state?.orderReducer?.internalVehicleWashing
export const methodOfPaymentOrderSelector = state => state?.orderReducer?.methodOfPayment
export const noteOrderSelector = state => state?.orderReducer?.note
export const vehicleColorOrderSelector = state => state?.orderReducer?.vehicleColor
export const vehiclePlatesOrderSelector = state => state?.orderReducer?.vehiclePlates
export const vehicleTypeOrderSelector = state => state?.orderReducer?.vehicleType

// supercard
export const _idSupercardSelector = state => state?.supercardReducer?._id
export const amountSupercardSelector = state => state?.supercardReducer?.amount
export const dateSupercardSelector = state => state?.supercardReducer?.date
export const noteSupercardSelector = state => state?.supercardReducer?.note
export const numberSupercardSelector = state => state?.supercardReducer?.number
export const ownerSupercardSelector = state => state?.supercardReducer?.owner
export const phoneSupercardSelector = state => state?.supercardReducer?.phone
export const searchValueSupercardSelector = state => state?.supercardReducer?.searchValue

// history
export const searchValueHistorySelector = state => state?.historyReducer?.searchValue