import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { ReactComponent as IconCancel } from './assets/icon-cancel.svg'
import { Col, Container, Row } from 'react-bootstrap'
import { amountSupercardSelector, dateSupercardSelector, noteSupercardSelector, numberSupercardSelector, ownerSupercardSelector, phoneSupercardSelector, _idSupercardSelector } from '../../redux/selectors'
import { supercardAction } from '../../redux/actions'
import { v4 as uuidv4 } from 'uuid'

import Layout from '../../components/Layout'

import './SupercardOrder.style.css'
import { serverTimestamp, SUPERCARDS_COLLECTION } from '../../firebase'
import { Link } from 'react-router-dom'


const SupercardOrder = () => {

    const _idSupercard = useSelector(_idSupercardSelector)
    const amountSupercard = useSelector(amountSupercardSelector)
    const dateSupercard = useSelector(dateSupercardSelector)
    const noteSupercard = useSelector(noteSupercardSelector)
    const numberSupercard = useSelector(numberSupercardSelector)
    const ownerSupercard = useSelector(ownerSupercardSelector)
    const phoneSupercard = useSelector(phoneSupercardSelector)

    const { id } = useParams()

    const dispatch = useDispatch()

    const history = useHistory()

    useEffect(() => {

        if (id) {
            const getOrders = async () => {

                const snapshot = await SUPERCARDS_COLLECTION.where('_id', '==', id).get()

                snapshot.forEach(doc => {

                    const data = doc.data()

                    dispatch(supercardAction({
                        _id: data._id,
                        amount: data.amount,
                        date: data.date,
                        note: data.note,
                        number: data.number,
                        owner: data.owner,
                        phone: data.phone
                    }))

                })
            }

            getOrders()
        }

        document.body.style = 'background: #F4F4F4';

    }, [])

    const handleEdit = async (e) => {

        e.preventDefault()

        await SUPERCARDS_COLLECTION.doc(id).set({
            _id: _idSupercard,
            amount: amountSupercard,
            date: dateSupercard,
            note: noteSupercard,
            number: numberSupercard,
            owner: ownerSupercard,
            phone: phoneSupercard
        })
            .then(() => {
                history.push(`/supercard`)
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    const handleAdd = async (e) => {

        e.preventDefault()

        const uuid = uuidv4()

        await SUPERCARDS_COLLECTION.doc(uuid).set({
            _id: uuid,
            amount: amountSupercard,
            date: serverTimestamp.now(),
            note: noteSupercard,
            number: numberSupercard,
            owner: ownerSupercard,
            phone: phoneSupercard
        })
            .then(() => {
                history.push('/supercard')
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    const handleDelete = () => {
        SUPERCARDS_COLLECTION.doc(id).delete()
            .then(() => {
                history.push('/supercard')
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    return (
        <>
            <div className='supercard-order'>
                <Layout none>
                    <Container fluid>
                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3}>
                                {id && <div className='supercard-order__label-delete' onClick={handleDelete}>
                                    Deaktiviraj
                                </div>}
                            </Col>
                            <Col xs={9} sm={9} md={9} lg={9}>
                                <Link to='/supercard'>
                                    <IconCancel className='supercard-order__icon-cancel' />
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='supercard-order__title'>
                                    {id ? 'Izmjena' : 'Dodaj karticu'}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <input
                                    className='supercard-order__input'
                                    placeholder='ID kartice'
                                    type='text'
                                    value={numberSupercard.toUpperCase()}
                                    onChange={(e) => dispatch(supercardAction({ number: e.target.value }))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='supercard-order__label'>
                                    Ime vlasnika
                                </div>
                                <input
                                    className='supercard-order__input'
                                    placeholder='Marko Marković'
                                    type='text'
                                    value={ownerSupercard}
                                    onChange={(e) => dispatch(supercardAction({ owner: e.target.value }))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='supercard-order__label'>
                                    Broj telefona
                                </div>
                                <input
                                    className='supercard-order__input'
                                    placeholder='065 123 456'
                                    type='tel'
                                    value={phoneSupercard}
                                    onChange={(e) => dispatch(supercardAction({ phone: e.target.value }))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='supercard-order__label'>
                                    Napomena
                                </div>
                                <textarea
                                    className='supercard-order__textarea'
                                    value={noteSupercard}
                                    onChange={(e) => dispatch(supercardAction({ note: e.target.value }))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='supercard-order__label'>
                                    Dopuna kartice
                                </div>
                                <div className="supercard-order__group-shortcut">
                                    <button
                                        className='supercard-order__button-shortcut'
                                        onClick={() => dispatch(supercardAction({ amount: '30' }))}
                                    >
                                        35
                                    </button>
                                    <button
                                        className='supercard-order__button-shortcut'
                                        onClick={() => dispatch(supercardAction({ amount: '60' }))}
                                    >
                                        60
                                    </button>
                                    <button
                                        className='supercard-order__button-shortcut'
                                        onClick={() => dispatch(supercardAction({ amount: '120' }))}
                                    >
                                        120
                                    </button>
                                </div>
                                <input
                                    className='supercard-order__input'
                                    placeholder='Iznos'
                                    type='number'
                                    value={amountSupercard}
                                    onChange={(e) => dispatch(supercardAction({ amount: e.target.value }))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {
                                    id
                                        ?
                                        <button
                                            className={`supercard-order__button-submit ${amountSupercard && numberSupercard ? null : 'supercard-order__button-submit--disabled'}`}
                                            disabled={amountSupercard && numberSupercard ? false : true}
                                            onClick={handleEdit}
                                        >
                                            Sačuvaj
                                        </button>
                                        :
                                        <button
                                            className={`supercard-order__button-submit ${amountSupercard && numberSupercard ? null : 'supercard-order__button-submit--disabled'}`}
                                            disabled={amountSupercard && numberSupercard ? false : true}
                                            onClick={handleAdd}
                                        >
                                            Dodaj
                                        </button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Layout>
            </div >
        </>
    )
}

export default SupercardOrder
