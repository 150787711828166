export const searchAction = (payload) => ({
    type: 'SEARCH',
    payload
})

export const orderAction = (payload) => ({
    type: 'ORDER',
    payload
})
export const supercardAction = (payload) => ({
    type: 'SUPERCARD',
    payload
})

export const historyAction = (payload) => ({
    type: 'HISTORY',
    payload
})
