import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { valueSearchSelector } from '../../redux/selectors'
import { searchAction } from '../../redux/actions'

import './Search.style.css'

import { ReactComponent as IconBack } from './assets/icon-back.svg'

const Search = () => {

    const location = useLocation()

    const dispatch = useDispatch()

    const valueSearch = useSelector(valueSearchSelector)

    return (
        <>
            {location.pathname === '/search' &&
                <div className='search'>
                    <Link to='/'>
                        <IconBack className='search__icon-back' />
                    </Link>
                    <input className='search__input' type='search' autoFocus value={valueSearch} onChange={(e) => dispatch(searchAction({ value: e.target.value }))} />
                    {/* <div className='search__results'>{resultsSearch} rezultata</div> */}
                </div>
            }
        </>
    )
}

export default Search
