import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

import './CardOrder.style.css'

import { ReactComponent as IconEdit } from './assets/icon-edit.svg'
import { ReactComponent as IconCheckmark } from './assets/icon-checkmark.svg'

const CardOrder = ({ id, amountTotal, donePerson, doneTime, enterPerson, enterTime, exteriorVehicleWashingPaid, exteriorVehicleWashing, internalVehicleWashing, methodOfPayment, note, vehicleColor, vehiclePlates, vehicleType }) => {

    const location = useLocation()

    return (
        <>
            <div
                className={`card-order ${(doneTime && location.pathname === '/') && 'card-order-done'}`}
                style={{ borderLeft: `16px solid ${vehicleColor} ` }}
            >
                <Container fluid>
                    <Row>

                        <Col xs={6} sm={6} md={6} lg={6}>
                            <div className='card-order__group'>
                                <div className='card-order__label-three card-order__color-two'>{vehicleType}</div>
                                <div className='card-order__label-one card-order__color-one card-order__ellipsis'>{vehiclePlates}</div>
                            </div>
                        </Col>

                        {!doneTime
                            ?
                            <>
                                <Col xs={6} sm={6} md={3} lg={3}></Col>
                                <Col xs={12} sm={12} md={3} lg={3}>
                                    <div className='card-order__group card-order__flex'>
                                        <Link to={`/order/${id}`}>
                                            <IconEdit className='card-order__icon-edit' />
                                        </Link>
                                        {
                                            methodOfPayment === 'ŽIRALNO'
                                                ?
                                                <Link to={`/done/${id}`}>
                                                    <IconCheckmark className='card-order__icon-checkmark' />
                                                </Link>
                                                :
                                                <Link to={`/done/${id}`}>
                                                    <button className='card-order__button-amount'>
                                                        {amountTotal}<span className='card-order__span-amount'>KM</span>
                                                    </button>
                                                </Link>
                                        }
                                    </div>
                                </Col>
                            </>
                            :
                            <>
                                <Col xs={6} sm={6} md={3} lg={3}>
                                    <div className='card-order__group'>
                                        <div className='card-order__label-three card-order__color-two'>Uneseno</div>
                                        <div className='card-order__label-two card-order__color-one card-order__ellipsis'>{`${enterTime} ${enterPerson}`}</div>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} md={3} lg={3}>
                                    <div className='card-order__group'>
                                        <div className='card-order__label-three card-order__color-two'>Završeno</div>
                                        <div className='card-order__label-two card-order__color-one card-order__ellipsis'>{`${doneTime} ${donePerson}`}</div>
                                    </div>
                                </Col>
                            </>
                        }

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='card-order__group'>
                                <div className="card-order__border"></div>
                            </div>
                        </Col>

                        <Col xs={6} sm={6} md={3} lg={3}>
                            <div className='card-order__group'>
                                <div className='card-order__label-three card-order__color-two'>Vanjsko</div>
                                <div className={`card-order__label-two card-order__ellipsis card-order__color-one ${exteriorVehicleWashing === 'PREMIUM' && 'card-order__color-three'} ${exteriorVehicleWashing === 'KOMERCIJALNO' && 'card-order__color-four'}`}>
                                    {exteriorVehicleWashing}
                                </div>
                            </div>
                        </Col>

                        <Col xs={6} sm={6} md={3} lg={3}>
                            <div className='card-order__group'>
                                <div className='card-order__label-three card-order__color-two'>Unutrašnje</div>
                                <div className={`card-order__label-two card-order__ellipsis card-order__color-one ${internalVehicleWashing === 'PREMIUM' && 'card-order__color-three'} ${internalVehicleWashing === 'KOMERCIJALNO' && 'card-order__color-four'}`}>
                                    {internalVehicleWashing}
                                </div>
                            </div>
                        </Col>

                        <Col xs={6} sm={6} md={3} lg={3}>
                            <div className='card-order__group'>
                                <div className='card-order__label-three card-order__color-two'>Vanjsko plaćeno</div>
                                <div className='card-order__label-two card-order__color-one card-order__ellipsis'>{exteriorVehicleWashingPaid}</div>
                            </div>
                        </Col>

                        <Col xs={6} sm={6} md={3} lg={3}>
                            <div className='card-order__group'>
                                <div className='card-order__label-three card-order__color-two'>Način plaćanja</div>
                                <div className='card-order__label-two card-order__color-one card-order__ellipsis'>{methodOfPayment}</div>
                            </div>
                        </Col>

                        {note &&
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='card-order__group'>
                                    <div className='card-order__label-three card-order__color-two'>Napomena</div>
                                    <div className='card-order__label-two card-order__color-one'>{note}</div>
                                </div>
                            </Col>
                        }

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CardOrder
