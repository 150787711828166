import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { orderAction } from '../../redux/actions'
import { useHistory, useParams } from 'react-router-dom'
import { _idOrderSelector, doneTimeOrderSelector, donePersonOrderSelector, enterPersonOrderSelector, enterTimeOrderSelector, exteriorVehicleWashingPaidOrderSelector, exteriorVehicleWashingOrderSelector, internalVehicleWashingOrderSelector, methodOfPaymentOrderSelector, noteOrderSelector, vehicleColorOrderSelector, vehiclePlatesOrderSelector, vehicleTypeOrderSelector, amountTotalOrderSelector, amountInternalVehicleWashingOrderSelector, amountExteriorVehicleWashingOrderSelector } from '../../redux/selectors'
import { ORDERS_COLLECTION } from '../../firebase'

import './Note.style.css'

import { ReactComponent as IconCancel } from './assets/icon-cancel.svg'
import { ReactComponent as IconReset } from './assets/icon-reset.svg'

const Note = () => {

    const { id } = useParams()

    const dispatch = useDispatch()

    const history = useHistory()

    const _idOrder = useSelector(_idOrderSelector)
    const amountTotalOrder = useSelector(amountTotalOrderSelector)
    const amountInternalVehicleWashingOrder = useSelector(amountInternalVehicleWashingOrderSelector)
    const amountExteriorVehicleWashingOrder = useSelector(amountExteriorVehicleWashingOrderSelector)
    const donePersonOrder = useSelector(donePersonOrderSelector)
    const doneTimeOrder = useSelector(doneTimeOrderSelector)
    const enterPersonOrder = useSelector(enterPersonOrderSelector)
    const enterTimeOrder = useSelector(enterTimeOrderSelector)
    const exteriorVehicleWashingPaidOrder = useSelector(exteriorVehicleWashingPaidOrderSelector)
    const exteriorVehicleWashingOrder = useSelector(exteriorVehicleWashingOrderSelector)
    const internalVehicleWashingOrder = useSelector(internalVehicleWashingOrderSelector)
    const methodOfPaymentOrder = useSelector(methodOfPaymentOrderSelector)
    const noteOrder = useSelector(noteOrderSelector)
    const vehicleColorOrder = useSelector(vehicleColorOrderSelector)
    const vehiclePlatesOrder = useSelector(vehiclePlatesOrderSelector)
    const vehicleTypeOrder = useSelector(vehicleTypeOrderSelector)

    useEffect(() => {

        if (id) {

            const getOrders = async () => {

                const snapshot = await ORDERS_COLLECTION.where('_id', '==', id).get()

                snapshot.forEach(doc => {

                    const data = doc.data()

                    dispatch(orderAction({
                        note: data.note,
                    }))

                })
            }

            getOrders()
        }

        document.body.style = 'background: #e5e5ea'

    }, [])


    const handleEdit = async (e) => {

        e.preventDefault()

        await ORDERS_COLLECTION.doc(id).set({
            _id: _idOrder,
            amountTotal: amountTotalOrder,
            amountExteriorVehicleWashing: amountExteriorVehicleWashingOrder,
            amountInternalVehicleWashing: amountInternalVehicleWashingOrder,
            donePerson: donePersonOrder,
            doneTime: doneTimeOrder,
            enterPerson: enterPersonOrder,
            enterTime: enterTimeOrder,
            exteriorVehicleWashingPaid: exteriorVehicleWashingPaidOrder,
            exteriorVehicleWashing: exteriorVehicleWashingOrder,
            internalVehicleWashing: internalVehicleWashingOrder,
            methodOfPayment: methodOfPaymentOrder,
            note: noteOrder,
            vehicleColor: vehicleColorOrder,
            vehiclePlates: vehiclePlatesOrder,
            vehicleType: vehicleTypeOrder
        })
            .then(() => {
                history.goBack()
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    return (
        <>
            <div className="note">
                <IconCancel
                    className='note__icon-cancel'
                    onClick={() => history.goBack()}
                />
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="note__title">
                                Napomena
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <button
                                className="note__button-shotcut"
                                onClick={() => dispatch(orderAction({ note: 'Crna krpa. ' }))}
                            >
                                Crna krpa.
                            </button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <button
                                className="note__button-shotcut"
                                onClick={() => dispatch(orderAction({ note: 'K2 za gume. ' }))}
                            >
                                K2 za gume.
                            </button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <button
                                className="note__button-shotcut"
                                onClick={() => dispatch(orderAction({ note: 'Bez glicerina. ' }))}
                            >
                                Bez glicerina.
                            </button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <button
                                className="note__button-shotcut"
                                onClick={() => dispatch(orderAction({ note: 'Bez papirnih podmetača. ' }))}
                            >
                                Bez papirnih podmetača.
                            </button>
                        </Col>
                    </Row >
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <textarea
                                className='note__textarea'
                                value={noteOrder}
                                onChange={(e) => dispatch(orderAction({ note: e.target.value }))}
                                autoFocus
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={10} sm={10} md={10} lg={10}>
                            {
                                id
                                    ?
                                    <button
                                        className="note__button-submit"
                                        onClick={handleEdit}
                                    >
                                        Sačuvaj
                                    </button>
                                    :
                                    <button
                                        className={`note__button-submit ${noteOrder === '' && 'note__button-submit--disabled'}`}
                                        disabled={noteOrder === '' && true}
                                        onClick={() => history.goBack()}
                                    >
                                        Sačuvaj
                                    </button>

                            }
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2}>
                            <button
                                className={`note__button-reset ${noteOrder === '' && 'note__button-reset--disabled'}`}
                                onClick={() => dispatch(orderAction({ note: '' }))}
                            >
                                <IconReset className='note__icon-reset' />
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Note