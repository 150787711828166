import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ORDERS_COLLECTION } from '../../firebase'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { orderAction } from '../../redux/actions'
import _ from "lodash"

import './Dashboard.style.css'

import Navbar from '../../components/Navbar'
import Layout from '../../components/Layout'
import CardOrder from '../../components/CardOrder'

const Dashboard = () => {

    const dispatch = useDispatch()

    const [orders, setOrders] = useState([])
    const [ordersLoading, setOrdersLoading] = useState(false)

    const [open, setOpen] = useState(true)

    useEffect(() => {

        const getOrders = async () => {
            setOrdersLoading(true)
            const snapshot = await ORDERS_COLLECTION.orderBy("enterTime", "desc").limit(100).get()
            const orders = []
            snapshot.forEach(doc => {
                orders.push(doc.data())
            })
            setOrders(orders)
            setOrdersLoading(false)
        }

        // const getOrders = () => {

        //     setOrdersLoading(true)

        //     ORDERS_COLLECTION.orderBy("enterTime", "desc").onSnapshot((querySnapshot) => {

        //         const orders = []

        //         querySnapshot.forEach(doc => {
        //             orders.push(doc.data())
        //         })

        //         setOrders(orders)
        //         setOrdersLoading(false)
        //     })

        // }

        getOrders()

        dispatch(orderAction({
            _id: '',
            amountTotal: '',
            amountExteriorVehicleWashing: '',
            amountInternalVehicleWashing: '',
            donePerson: '',
            doneTime: '',
            enterPerson: '',
            enterTime: '',
            exteriorVehicleWashingPaid: '',
            exteriorVehicleWashing: '',
            internalVehicleWashing: '',
            methodOfPayment: '',
            note: '',
            vehicleColor: '',
            vehiclePlates: '',
            vehicleType: ''
        }))

        document.body.style = 'background: #e5e5ea';

    }, [])

    const ordersGroups = _.chain(orders)
        .groupBy(group => group.enterTime && moment(group.enterTime.toDate()).format('DD.MM.YYYY'))
        .map((value, key) => ({ enterTime: key, data: value }))
        .value()

    return (
        <>
            <Navbar />
            <Layout normal>
                <Container fluid>
                    <Row>
                        {
                            !ordersLoading
                                ?
                                ordersGroups
                                    .map((a, index) => {
                                        return (
                                            <Col lg={12} key={index}>
                                                <div>

                                                    {
                                                        a.enterTime && a.enterTime === moment().format('DD.MM.YYYY')
                                                            ?
                                                            null
                                                            :
                                                            <div className='dashboard__group'>
                                                                <div className='dashboard__label-group'>
                                                                    {a.data.filter((b) => { return b.exteriorVehicleWashing !== 'NE' }).length} vanjsko
                                                                </div>
                                                                <button className='dashboard__button-group' onClick={() => setOpen(!open)}>
                                                                    {a.enterTime}
                                                                </button>
                                                                <div className='dashboard__label-group'>
                                                                    {a.data.filter((b) => { return b.internalVehicleWashing !== 'NE' }).length} unutrašnje
                                                                </div>
                                                            </div>
                                                    }

                                                    {open &&
                                                        a.data
                                                            .sort((a, b) => (a.doneTime && a.doneTime) - (b.doneTime && b.doneTime))
                                                            .map((b, index) => {
                                                                return (
                                                                    <CardOrder
                                                                        key={index}
                                                                        id={b._id}
                                                                        amountTotal={b.exteriorVehicleWashingPaid === 'NE' ? b.amountTotal : b.amountInternalVehicleWashing}
                                                                        donePerson={b.donePerson}
                                                                        doneTime={b.doneTime && moment(b.doneTime.toDate()).format('h:mm')}
                                                                        enterPerson={b.enterPerson}
                                                                        enterTime={b.enterTime && moment(b.enterTime.toDate()).format('h:mm')}
                                                                        exteriorVehicleWashingPaid={b.exteriorVehicleWashingPaid}
                                                                        exteriorVehicleWashing={b.exteriorVehicleWashing}
                                                                        internalVehicleWashing={b.internalVehicleWashing}
                                                                        methodOfPayment={b.methodOfPayment}
                                                                        note={b.note}
                                                                        vehicleColor={b.vehicleColor}
                                                                        vehiclePlates={b.vehiclePlates}
                                                                        vehicleType={b.vehicleType}
                                                                    />
                                                                )
                                                            })
                                                    }

                                                </div>
                                            </Col>
                                        )
                                    })
                                :
                                null
                        }
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default Dashboard
