import React from 'react'
import { auth } from '../../firebase'

const SignOut = () => {
    return (
        <>
            <button onClick={() => auth.signOut()}>
                Odjava
            </button>
        </>
    )
}

export default SignOut
