import React, { useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { _idOrderSelector, doneTimeOrderSelector, donePersonOrderSelector, enterPersonOrderSelector, enterTimeOrderSelector, exteriorVehicleWashingPaidOrderSelector, exteriorVehicleWashingOrderSelector, internalVehicleWashingOrderSelector, methodOfPaymentOrderSelector, noteOrderSelector, vehicleColorOrderSelector, vehiclePlatesOrderSelector, vehicleTypeOrderSelector, amountTotalOrderSelector, amountInternalVehicleWashingOrderSelector, amountExteriorVehicleWashingOrderSelector } from '../../redux/selectors'
import { orderAction } from '../../redux/actions'
import { ORDERS_COLLECTION } from '../../firebase'

import './Order.style.css'

import { ReactComponent as IconCancel } from './assets/icon-cancel.svg'
import { ReactComponent as IconNo } from './assets/icon-no.svg'
import { ReactComponent as IconNoActive } from './assets/icon-no-active.svg'
import { ReactComponent as IconYes } from './assets/icon-yes.svg'
import { ReactComponent as IconYesActive } from './assets/icon-yes-active.svg'
import { useState } from 'react'

const Order = () => {

    const { id } = useParams()

    const dispatch = useDispatch()

    const history = useHistory()

    const _idOrder = useSelector(_idOrderSelector)
    const amountTotalOrder = useSelector(amountTotalOrderSelector)
    const amountInternalVehicleWashingOrder = useSelector(amountInternalVehicleWashingOrderSelector)
    const amountExteriorVehicleWashingOrder = useSelector(amountExteriorVehicleWashingOrderSelector)
    const donePersonOrder = useSelector(donePersonOrderSelector)
    const doneTimeOrder = useSelector(doneTimeOrderSelector)
    const enterPersonOrder = useSelector(enterPersonOrderSelector)
    const enterTimeOrder = useSelector(enterTimeOrderSelector)
    const exteriorVehicleWashingPaidOrder = useSelector(exteriorVehicleWashingPaidOrderSelector)
    const exteriorVehicleWashingOrder = useSelector(exteriorVehicleWashingOrderSelector)
    const internalVehicleWashingOrder = useSelector(internalVehicleWashingOrderSelector)
    const methodOfPaymentOrder = useSelector(methodOfPaymentOrderSelector)
    const noteOrder = useSelector(noteOrderSelector)
    const vehicleColorOrder = useSelector(vehicleColorOrderSelector)
    const vehiclePlatesOrder = useSelector(vehiclePlatesOrderSelector)
    const vehicleTypeOrder = useSelector(vehicleTypeOrderSelector)

    const [orders, setOrders] = useState([])
    const [ordersLoading, setOrdersLoading] = useState(false)

    useEffect(() => {

        const getOrders = async () => {
            setOrdersLoading(true)
            const snapshot = await ORDERS_COLLECTION.orderBy("enterTime", "desc").limit(1500).get()
            const orders = []
            snapshot.forEach(doc => {
                orders.push(doc.data())
            })
            setOrders(orders)
            setOrdersLoading(false)
        }

        getOrders()

        if (id) {
            const getOrders = async () => {

                const snapshot = await ORDERS_COLLECTION.where('_id', '==', id).get()

                snapshot.forEach(doc => {

                    const data = doc.data()

                    dispatch(orderAction({
                        _id: data._id,
                        amountTotal: data.amountTotal,
                        amountExteriorVehicleWashing: data.amountExteriorVehicleWashing,
                        amountInternalVehicleWashing: data.amountInternalVehicleWashing,
                        donePerson: data.donePerson,
                        doneTime: data.doneTime,
                        enterPerson: data.enterPerson,
                        enterTime: data.enterTime,
                        exteriorVehicleWashingPaid: data.exteriorVehicleWashingPaid,
                        exteriorVehicleWashing: data.exteriorVehicleWashing,
                        internalVehicleWashing: data.internalVehicleWashing,
                        methodOfPayment: data.methodOfPayment,
                        note: data.note,
                        vehicleColor: data.vehicleColor,
                        vehiclePlates: data.vehiclePlates,
                        vehicleType: data.vehicleType
                    }))

                })
            }

            getOrders()
        }

        document.body.style = 'background: #f4f4f4'

    }, [])


    const handleEdit = async (e) => {

        e.preventDefault()

        await ORDERS_COLLECTION.doc(id).set({
            _id: _idOrder,
            amountTotal: amountTotalOrder,
            amountExteriorVehicleWashing: amountExteriorVehicleWashingOrder,
            amountInternalVehicleWashing: amountInternalVehicleWashingOrder,
            donePerson: donePersonOrder,
            doneTime: doneTimeOrder,
            enterPerson: enterPersonOrder,
            enterTime: enterTimeOrder,
            exteriorVehicleWashingPaid: exteriorVehicleWashingPaidOrder,
            exteriorVehicleWashing: exteriorVehicleWashingOrder,
            internalVehicleWashing: internalVehicleWashingOrder,
            methodOfPayment: methodOfPaymentOrder,
            note: noteOrder,
            vehicleColor: vehicleColorOrder,
            vehiclePlates: vehiclePlatesOrder,
            vehicleType: vehicleTypeOrder
        })
            .then(() => {
                history.push(`/enter/${id}`)
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    const filterOrders = orders.filter(order => order?.vehiclePlates.toLowerCase().includes(vehiclePlatesOrder.toLowerCase()) ? true : false)

    return (
        <>
            <div className="order">
                <Container fluid>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            {
                                id
                                    ?
                                    <Link to={`/note/${id}`}>
                                        <button className={`order__button-note ${noteOrder !== '' && 'order__button-note--active'}`}>
                                            Napomena
                                        </button>
                                    </Link>
                                    :
                                    <Link to='/note'>
                                        <button className={`order__button-note ${noteOrder !== '' && 'order__button-note--active'}`}>
                                            Napomena
                                        </button>
                                    </Link>
                            }
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <Link to='/'>
                                <IconCancel className='order__icon-cancel' />
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="order__title">
                                {id ? 'Izmjeni' : 'Dodaj vozilo'}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__wrapper-vehicle-plates'>
                                <input
                                    type='search'
                                    className='order__input-vehicle-plates'
                                    placeholder='Registracija'
                                    value={vehiclePlatesOrder.toUpperCase()}
                                    onChange={(e) => dispatch(orderAction({ vehiclePlates: e.target.value }))}
                                    autoFocus={id ? false : true}
                                />
                                {
                                    vehiclePlatesOrder !== ''
                                    &&
                                    <div className='order__group-vehicle-plates'>
                                        {
                                            filterOrders.map(item => {
                                                return (
                                                    <React.Fragment key={item._id}>
                                                        {
                                                            vehiclePlatesOrder !== item.vehiclePlates
                                                            &&
                                                            <button
                                                                className='order__button-vehicle-plates'
                                                                onClick={() => dispatch(orderAction({
                                                                    // _id: '',
                                                                    // amountTotal: '',
                                                                    // amountExteriorVehicleWashing: item.amountExteriorVehicleWashing,
                                                                    // amountInternalVehicleWashing: item.amountInternalVehicleWashing,
                                                                    // donePerson: '',
                                                                    // doneTime: '',
                                                                    // enterPerson: '',
                                                                    // enterTime: '',
                                                                    exteriorVehicleWashingPaid: item.exteriorVehicleWashingPaid,
                                                                    exteriorVehicleWashing: item.exteriorVehicleWashing,
                                                                    internalVehicleWashing: item.internalVehicleWashing,
                                                                    methodOfPayment: item.methodOfPayment,
                                                                    note: item.note,
                                                                    vehicleColor: item.vehicleColor,
                                                                    vehiclePlates: item.vehiclePlates,
                                                                    vehicleType: item.vehicleType
                                                                }))}
                                                            >
                                                                {item.vehiclePlates}
                                                            </button>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-title'>
                                Boja
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-one'>
                                <button
                                    style={{ backgroundColor: '#171a20' }}
                                    className={`order__button-two ${vehicleColorOrder === '#171a20' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#171a20' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#171a20' }))}
                                />
                                <button
                                    style={{ backgroundColor: '#fff' }}
                                    className={`order__button-two ${vehicleColorOrder === '#fff' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#fff' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#fff' }))}
                                />
                                <button
                                    style={{ backgroundColor: '#a2a3a5' }}
                                    className={`order__button-two ${vehicleColorOrder === '#a2a3a5' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#a2a3a5' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#a2a3a5' }))}
                                />
                                <button
                                    style={{ backgroundColor: '#3e6be1' }}
                                    className={`order__button-two ${vehicleColorOrder === '#3e6be1' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#3e6be1' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#3e6be1' }))}
                                />
                                <button
                                    style={{ backgroundColor: '#67c734' }}
                                    className={`order__button-two ${vehicleColorOrder === '#67c734' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#67c734' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#67c734' }))}
                                />
                                <button
                                    style={{ backgroundColor: '#fbc92e' }}
                                    className={`order__button-two ${vehicleColorOrder === '#fbc92e' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#fbc92e' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#fbc92e' }))}
                                />
                                <button
                                    style={{ backgroundColor: '#fd9526' }}
                                    className={`order__button-two ${vehicleColorOrder === '#fd9526' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#fd9526' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#fd9526' }))}
                                />
                                <button
                                    style={{ backgroundColor: '#fd3a2f' }}
                                    className={`order__button-two ${vehicleColorOrder === '#fd3a2f' && 'order__button-two--active'}`}
                                    onClick={vehicleColorOrder === '#fd3a2f' ? () => dispatch(orderAction({ vehicleColor: '' })) : () => dispatch(orderAction({ vehicleColor: '#fd3a2f' }))}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-title'>
                                Kategorija
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-one'>
                                <button
                                    className={`order__button-one ${vehicleTypeOrder === 'AUTO/SUV/VAN' && 'order__button-one--active'}`}
                                    onClick={vehicleTypeOrder === 'AUTO/SUV/VAN' ? () => dispatch(orderAction({ vehicleType: '' })) : () => dispatch(orderAction({ vehicleType: 'AUTO/SUV/VAN' }))}
                                >
                                    AUTO/SUV/VAN
                                </button>
                                <button
                                    className={`order__button-one ${vehicleTypeOrder === 'KOMBI' && 'order__button-one--active'}`}
                                    onClick={vehicleTypeOrder === 'KOMBI' ? () => dispatch(orderAction({ vehicleType: '' })) : () => dispatch(orderAction({ vehicleType: 'KOMBI' }))}
                                >
                                    KOMBI
                                </button>
                                <button
                                    className={`order__button-one ${vehicleTypeOrder === 'KAMION' && 'order__button-one--active'}`}
                                    onClick={vehicleTypeOrder === 'KAMION' ? () => dispatch(orderAction({ vehicleType: '' })) : () => dispatch(orderAction({ vehicleType: 'KAMION' }))}
                                >
                                    KAMION
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-title'>
                                Vanjsko pranje
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-one'>
                                <button
                                    className={`order__button-one ${exteriorVehicleWashingOrder === 'NE' && 'order__button-one--active'}`}
                                    onClick={exteriorVehicleWashingOrder === 'NE' ? () => dispatch(orderAction({ exteriorVehicleWashing: '' })) : () => dispatch(orderAction({ exteriorVehicleWashing: 'NE' }))}
                                >
                                    NE
                                </button>
                                <button
                                    className={`order__button-one ${exteriorVehicleWashingOrder === 'KOMERCIJALNO' && 'order__button-one--active'}`}
                                    onClick={exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? () => dispatch(orderAction({ exteriorVehicleWashing: '' })) : () => dispatch(orderAction({ exteriorVehicleWashing: 'KOMERCIJALNO' }))}
                                >
                                    KOMERCIJALNO
                                </button>
                                <button
                                    className={`order__button-one ${exteriorVehicleWashingOrder === 'BRZI' && 'order__button-one--active'}`}
                                    onClick={exteriorVehicleWashingOrder === 'BRZI' ? () => dispatch(orderAction({ exteriorVehicleWashing: '' })) : () => dispatch(orderAction({ exteriorVehicleWashing: 'BRZI' }))}
                                >
                                    BRZI
                                </button>
                                <button
                                    className={`order__button-one ${exteriorVehicleWashingOrder === 'PREMIUM' && 'order__button-one--active'}`}
                                    onClick={exteriorVehicleWashingOrder === 'PREMIUM' ? () => dispatch(orderAction({ exteriorVehicleWashing: '' })) : () => dispatch(orderAction({ exteriorVehicleWashing: 'PREMIUM' }))}
                                >
                                    PREMIUM
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-title'>
                                Unutrašnje pranje
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-one'>
                                <button
                                    className={`order__button-one ${internalVehicleWashingOrder === 'NE' && 'order__button-one--active'}`}
                                    onClick={internalVehicleWashingOrder === 'NE' ? () => dispatch(orderAction({ internalVehicleWashing: '' })) : () => dispatch(orderAction({ internalVehicleWashing: 'NE' }))}
                                >
                                    NE
                                </button>
                                <button
                                    className={`order__button-one ${internalVehicleWashingOrder === 'BRZI' && 'order__button-one--active'}`}
                                    onClick={internalVehicleWashingOrder === 'BRZI' ? () => dispatch(orderAction({ internalVehicleWashing: '' })) : () => dispatch(orderAction({ internalVehicleWashing: 'BRZI' }))}
                                >
                                    BRZI
                                </button>
                                <button
                                    className={`order__button-one ${internalVehicleWashingOrder === 'PREMIUM' && 'order__button-one--active'}`}
                                    onClick={internalVehicleWashingOrder === 'PREMIUM' ? () => dispatch(orderAction({ internalVehicleWashing: '' })) : () => dispatch(orderAction({ internalVehicleWashing: 'PREMIUM' }))}
                                >
                                    PREMIUM
                                </button>
                                <button
                                    className={`order__button-one ${internalVehicleWashingOrder === 'PREMIUM PLUS' && 'order__button-one--active'}`}
                                    onClick={internalVehicleWashingOrder === 'PREMIUM PLUS' ? () => dispatch(orderAction({ internalVehicleWashing: '' })) : () => dispatch(orderAction({ internalVehicleWashing: 'PREMIUM PLUS' }))}
                                >
                                    PREMIUM PLUS
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-title'>
                                Vanjsko plaćeno
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-one'>
                                <button
                                    className={`order__button-one ${exteriorVehicleWashingPaidOrder === 'NE' && 'order__icon-no--active'}`}
                                    onClick={exteriorVehicleWashingPaidOrder === 'NE' ? () => dispatch(orderAction({ exteriorVehicleWashingPaid: '' })) : () => dispatch(orderAction({ exteriorVehicleWashingPaid: 'NE' }))}
                                >
                                    {
                                        exteriorVehicleWashingPaidOrder === 'NE'
                                            ?
                                            <IconNoActive className="order__icon-no" />
                                            :
                                            <IconNo className="order__icon-no" />
                                    }
                                </button>
                                <button
                                    className={`order__button-one ${exteriorVehicleWashingPaidOrder === 'DA' && 'order__button-one--active'}`}
                                    onClick={exteriorVehicleWashingPaidOrder === 'DA' ? () => dispatch(orderAction({ exteriorVehicleWashingPaid: '' })) : () => dispatch(orderAction({ exteriorVehicleWashingPaid: 'DA' }))}
                                >
                                    {
                                        exteriorVehicleWashingPaidOrder === 'DA'
                                            ?
                                            <IconYesActive className="order__icon-yes" />
                                            :
                                            <IconYes className="order__icon-yes" />
                                    }
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-title'>
                                Način plaćanja
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='order__button-group-one'>
                                <button
                                    className={`order__button-one ${methodOfPaymentOrder === 'ŽIRALNO' && 'order__button-one--active'}`}
                                    onClick={methodOfPaymentOrder === 'ŽIRALNO' ? () => dispatch(orderAction({ methodOfPayment: '' })) : () => dispatch(orderAction({ methodOfPayment: 'ŽIRALNO' }))}
                                >
                                    ŽIRALNO
                                </button>
                                <button
                                    className={`order__button-one ${methodOfPaymentOrder === 'GOTOVINA' && 'order__button-one--active'}`}
                                    onClick={methodOfPaymentOrder === 'GOTOVINA' ? () => dispatch(orderAction({ methodOfPayment: '' })) : () => dispatch(orderAction({ methodOfPayment: 'GOTOVINA' }))}
                                >
                                    GOTOVINA
                                </button>
                                <button
                                    className={`order__button-one ${methodOfPaymentOrder === 'SUPERKARTICA' && 'order__button-one--active'}`}
                                    onClick={methodOfPaymentOrder === 'SUPERKARTICA' ? () => dispatch(orderAction({ methodOfPayment: '' })) : () => dispatch(orderAction({ methodOfPayment: 'SUPERKARTICA' }))}
                                >
                                    SUPERKARTICA
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {
                                id
                                    ?
                                    <button
                                        className={`order__button-submit ${vehicleColorOrder && vehiclePlatesOrder && vehicleTypeOrder && exteriorVehicleWashingOrder && internalVehicleWashingOrder && exteriorVehicleWashingPaidOrder && methodOfPaymentOrder ? null : 'order__button-submit--disabled'}`}
                                        disabled={vehicleColorOrder && vehiclePlatesOrder && vehicleTypeOrder && exteriorVehicleWashingOrder && internalVehicleWashingOrder && exteriorVehicleWashingPaidOrder && methodOfPaymentOrder ? false : true}
                                        onClick={handleEdit}
                                    >
                                        Sačuvaj
                                    </button>
                                    :
                                    <Link to={`/enter`}>
                                        <button
                                            className={`order__button-submit ${vehicleColorOrder && vehiclePlatesOrder && vehicleTypeOrder && exteriorVehicleWashingOrder && internalVehicleWashingOrder && exteriorVehicleWashingPaidOrder && methodOfPaymentOrder ? null : 'order__button-submit--disabled'}`}
                                            disabled={vehicleColorOrder && vehiclePlatesOrder && vehicleTypeOrder && exteriorVehicleWashingOrder && internalVehicleWashingOrder && exteriorVehicleWashingPaidOrder && methodOfPaymentOrder ? false : true}
                                        >
                                            Dodaj
                                        </button>
                                    </Link>
                            }
                        </Col>
                    </Row>

                </Container>
            </div>
        </>
    )
}

export default Order