import React, { useCallback, useContext, useEffect } from 'react'

import './Login.style.css'

import { AuthContext } from "../../auth/Auth/Auth"
import { auth } from '../../firebase'
import { withRouter, Redirect } from "react-router"


const Login = ({ history }) => {

    useEffect(() => {

        document.body.style = 'background: #E20613';

    }, [])

    const handleLogin = useCallback(
        async event => {
            event.preventDefault()
            const { password } = event.target.elements
            try {
                await
                    auth
                        .signInWithEmailAndPassword('info@laserwasch.com', password.value)
                history.push("/")

            } catch (error) {
                alert(error)
            }
        },
        [history]
    )

    const { currentUser } = useContext(AuthContext)

    if (currentUser) {
        return <Redirect to="/" />
    }

    return (
        <div className='login'>
            <div className="login__card">
                <div className="login__title">
                    Evidencija rada
                </div>
                <div className="login__sub-title">
                    Lazarevo
                </div>
                <form onSubmit={handleLogin}>
                    <input className='login__input' name="password" type="password" placeholder='lozinka' />
                    <button type="submit" className='login__button'>
                        Prijavi se
                    </button>
                </form>
            </div>
        </div>
    )
}

export default withRouter(Login)