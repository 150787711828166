import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Search from './pages/Search'
import History from './pages/History'

import Order from './pages/Order'
import Done from './pages/Done'
import Enter from './pages/Enter'
import Note from './pages/Note'
import Supercard from './pages/Supercard'
import SignOut from './pages/SignOut'
import SupercardOrder from './pages/SupercardOrder'

import './assets/css/bootstrap-grid.css'
import './assets/css/main.css'

// auth
import { AuthProvider } from './auth/Auth/Auth'
import PrivateRoute from "./auth/PrivateRoute"

const App = () => {
	return (
		<>
			<AuthProvider>
				<Router>
					<Switch>

						<Route path="/login" exact component={Login} />

						<PrivateRoute path="/" exact component={Dashboard} />

						<PrivateRoute path="/search" exact component={Search} />

						<PrivateRoute path="/order" exact component={Order} />
						<PrivateRoute path="/order/:id" exact component={Order} />

						<PrivateRoute path="/history" exact component={History} />

						<PrivateRoute path="/done/:id" exact component={Done} />

						<PrivateRoute path="/enter" exact component={Enter} />
						<PrivateRoute path="/enter/:id" exact component={Enter} />

						<PrivateRoute path="/note" exact component={Note} />
						<PrivateRoute path="/note/:id" exact component={Note} />

						<PrivateRoute path="/supercard" exact component={Supercard} />

						<PrivateRoute path="/supercard-order" exact component={SupercardOrder} />
						<PrivateRoute path="/supercard-order/:id" exact component={SupercardOrder} />

						<PrivateRoute path="/sign-out" exact component={SignOut} />

					</Switch>
				</Router>
			</AuthProvider>
		</>
	)
}

export default App