import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './CardSupercard.style.css'

import { ReactComponent as IconEdit } from './assets/icon-edit.svg'

const CardSupercard = ({ id, date, number, amount, note, phone, owner }) => {

    const [open, setOpen] = useState(false)

    return (
        <div className='card-supercard' onClick={() => setOpen(!open)}>
            <Container fluid>
                <Row>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <div className='card-supercard__group'>
                            <div className='card-supercard__label-one card-supercard__color-one'>ID kartice</div>
                            <div className='card-supercard__label-two card-supercard__color-two'>{number}</div>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={3} lg={3}>
                        <div className='card-supercard__group'>
                            <div className='card-supercard__label-one card-supercard__color-one'>Dopunjeno</div>
                            <div className='card-supercard__label-two card-supercard__color-three'>{amount}KM</div>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={3} lg={3}>
                        <div className='card-supercard__group'>
                            <div className='card-supercard__label-one card-supercard__color-one'>Dana</div>
                            <div className='card-supercard__label-two card-supercard__color-three'>{date}</div>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={2} lg={2}>
                        <div className='card-supercard__group'>
                            <Link to={`/supercard-order/${id}`}>
                                <IconEdit className='card-supercard__icon-edit' />
                            </Link>
                        </div>
                    </Col>

                    {open &&
                        <>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='card-supercard__group'>
                                    <div className="card-supercard__border"></div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='card-supercard__group'>
                                    <div className='card-supercard__label-one card-supercard__color-one'>Vlasnik</div>
                                    <div className='card-supercard__label-three card-supercard__color-three'>{owner}</div>
                                </div>
                            </Col>
                            {phone &&
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className='card-supercard__group'>
                                            <div className="card-supercard__border"></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className='card-supercard__group'>
                                            <div className='card-supercard__label-one card-supercard__color-one'>Broj telefona</div>
                                            <div className='card-supercard__label-three card-supercard__color-three'>{phone}</div>
                                        </div>
                                    </Col>
                                </>
                            }
                            {note &&
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className='card-supercard__group'>
                                            <div className="card-supercard__border"></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className='card-supercard__group'>
                                            <div className='card-supercard__label-one card-supercard__color-one'>Napomena</div>
                                            <div className='card-supercard__label-three card-supercard__color-three'>{note}</div>
                                        </div>
                                    </Col>
                                </>
                            }
                        </>
                    }

                </Row>
            </Container>
        </div>
    )
}

export default CardSupercard
