import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { SUPERCARDS_COLLECTION } from '../../firebase'
import { searchValueSupercardSelector } from '../../redux/selectors'
import { Link } from 'react-router-dom'
import { orderAction, supercardAction } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import CardSupercard from '../../components/CardSupercard'
import Layout from '../../components/Layout'

import './Supercard.style.css'

import { ReactComponent as IconCancel } from './assets/icon-cancel.svg'


const Supercard = () => {

    const dispatch = useDispatch()

    const [supercards, setSupercards] = useState([])
    const [supercardsLoading, setSupercardsLoading] = useState(false)

    const searchValueSupercard = useSelector(searchValueSupercardSelector)

    useEffect(() => {

        const getOrders = async () => {
            setSupercardsLoading(true)
            const snapshot = await SUPERCARDS_COLLECTION.orderBy("date", "desc").get()
            const orders = []
            snapshot.forEach(doc => {
                orders.push(doc.data())
            })
            setSupercards(orders)
            setSupercardsLoading(false)
        }

        getOrders()

        dispatch(supercardAction({
            _id: '',
            amount: '',
            date: '',
            note: '',
            number: '',
            owner: '',
            phone: ''
        }))

        dispatch(orderAction({
            _id: '',
            amountTotal: '',
            amountExteriorVehicleWashing: '',
            amountInternalVehicleWashing: '',
            donePerson: '',
            doneTime: '',
            enterPerson: '',
            enterTime: '',
            exteriorVehicleWashingPaid: '',
            exteriorVehicleWashing: '',
            internalVehicleWashing: '',
            methodOfPayment: '',
            note: '',
            vehicleColor: '',
            vehiclePlates: '',
            vehicleType: ''
        }))

        document.body.style = 'background: #F4F4F4';

    }, [])

    const filterSupercards = supercards.filter(order => order?.number.toLowerCase().includes(searchValueSupercard.toLowerCase()) ? true : false)

    return (
        <div>
            <Layout none>
                <Container fluid>

                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <Link to='/supercard-order' style={{ textDecoration: 'none' }}>
                                <div className='supercard__label-add'>
                                    Dodaj
                                </div>
                            </Link>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <Link to='/'>
                                <IconCancel className='supercard__icon-cancel' />
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='supercard__title'>
                                Superkartica
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <input className='supercard__input' placeholder='Pretraga' type='search' value={searchValueSupercard} onChange={(e) => dispatch(supercardAction({ searchValue: e.target.value }))} />
                        </Col>
                    </Row>
                    <Row>
                        {
                            !supercardsLoading
                                ?
                                filterSupercards
                                    .map(supercard => {
                                        return (
                                            <Col key={supercard._id} lg={12}>
                                                <CardSupercard
                                                    id={supercard._id}
                                                    number={supercard.number}
                                                    amount={supercard.amount}
                                                    date={moment(supercard.date.toDate()).format('DD.MM.YYYY')}
                                                    note={supercard.note}
                                                    phone={supercard.phone}
                                                    owner={supercard.owner}
                                                />
                                            </Col>
                                        )
                                    })
                                :
                                null
                        }
                    </Row>
                </Container>
            </Layout>
        </div>
    )
}

export default Supercard
