import { combineReducers } from 'redux'

const searchInitialState = {
    value: '',
    results: 0
}

export const searchReducer = (state = searchInitialState, { type, payload }) => {
    switch (type) {

        case 'SEARCH':
            return { ...state, ...payload }

        default:
            return state
    }
}

const orderInitialState = {
    _id: '',
    amountTotal: '',
    amountExteriorVehicleWashing: '',
    amountInternalVehicleWashing: '',
    donePerson: '',
    doneTime: '',
    enterPerson: '',
    enterTime: '',
    exteriorVehicleWashingPaid: '',
    exteriorVehicleWashing: '',
    internalVehicleWashing: '',
    methodOfPayment: '',
    note: '',
    vehicleColor: '',
    vehiclePlates: '',
    vehicleType: ''
}

export const orderReducer = (state = orderInitialState, { type, payload }) => {
    switch (type) {

        case 'ORDER':
            return { ...state, ...payload }

        default:
            return state
    }
}

const supercardInitialState = {

    _id: '',
    amount: '',
    date: '',
    note: '',
    number: '',
    owner: '',
    phone: '',

    searchValue: ''
}

export const supercardReducer = (state = supercardInitialState, { type, payload }) => {
    switch (type) {

        case 'SUPERCARD':
            return { ...state, ...payload }

        default:
            return state
    }
}

const historyInitialState = {
    searchValue: ''
}

export const historyReducer = (state = historyInitialState, { type, payload }) => {
    switch (type) {

        case 'HISTORY':
            return { ...state, ...payload }

        default:
            return state
    }
}

const allReducers = combineReducers({
    searchReducer,
    orderReducer,
    supercardReducer,
    historyReducer
})

export default allReducers