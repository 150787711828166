import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ORDERS_COLLECTION } from '../../firebase'
import { valueSearchSelector } from '../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import Navbar from '../../components/Navbar'
import Layout from '../../components/Layout'
import SearchComponent from '../../components/Search'
import CardOrder from '../../components/CardOrder'
import { orderAction } from '../../redux/actions'

const Search = () => {

    const [orders, setOrders] = useState([])
    const [ordersLoading, setOrdersLoading] = useState(false)

    const valueSearch = useSelector(valueSearchSelector)

    const dispatch = useDispatch()

    useEffect(() => {

        const getOrders = async () => {
            setOrdersLoading(true)
            const snapshot = await ORDERS_COLLECTION.orderBy("enterTime", "desc").get()
            const orders = []
            snapshot.forEach(doc => {
                orders.push(doc.data())
            })
            setOrders(orders)
            setOrdersLoading(false)
        }

        getOrders()

        dispatch(orderAction({
            _id: '',
            amountTotal: '',
            amountExteriorVehicleWashing: '',
            amountInternalVehicleWashing: '',
            donePerson: '',
            doneTime: '',
            enterPerson: '',
            enterTime: '',
            exteriorVehicleWashingPaid: '',
            exteriorVehicleWashing: '',
            internalVehicleWashing: '',
            methodOfPayment: '',
            note: '',
            vehicleColor: '',
            vehiclePlates: '',
            vehicleType: ''
        }))

        document.body.style = 'background: #E5E5EA';

    }, [])

    const filterOrders = orders.filter(order => order?.vehiclePlates.toLowerCase().includes(valueSearch.toLowerCase()) ? true : false)

    return (
        <>
            <Navbar />
            <Layout search>
                <SearchComponent />
                <Container fluid>
                    <Row>
                        {
                            !ordersLoading
                                ?
                                filterOrders
                                    .map(order => {
                                        return (
                                            <Col key={order._id} lg={12}>
                                                <CardOrder
                                                    id={order._id}
                                                    amountTotal={order.amountTotal}
                                                    donePerson={order.donePerson}
                                                    doneTime={order.doneTime && moment(order.doneTime.toDate()).format('h:mm')}
                                                    enterPerson={order.enterPerson}
                                                    enterTime={order.enterTime && moment(order.enterTime.toDate()).format('h:mm')}
                                                    exteriorVehicleWashingPaid={order.exteriorVehicleWashingPaid}
                                                    exteriorVehicleWashing={order.exteriorVehicleWashing}
                                                    internalVehicleWashing={order.internalVehicleWashing}
                                                    methodOfPayment={order.methodOfPayment}
                                                    note={order.note}
                                                    vehicleColor={order.vehicleColor}
                                                    vehiclePlates={order.vehiclePlates}
                                                    vehicleType={order.vehicleType}
                                                />
                                            </Col>
                                        )
                                    })
                                :
                                null
                        }
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default Search
