import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ORDERS_COLLECTION } from '../../firebase'
import { Link } from 'react-router-dom'
import _ from "lodash"
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { searchValueHistorySelector } from '../../redux/selectors'
import { historyAction, orderAction } from '../../redux/actions'

import Layout from '../../components/Layout'
import CardHistory from '../../components/CardHistory'

import './History.style.css'

import { ReactComponent as IconCancel } from './assets/icon-cancel.svg'

const History = () => {

    const dispatch = useDispatch()

    const [orders, setOrders] = useState([])
    const [ordersLoading, setOrdersLoading] = useState(false)

    const searchValueHistory = useSelector(searchValueHistorySelector)

    useEffect(() => {

        const getOrders = async () => {
            setOrdersLoading(true)
            const snapshot = await ORDERS_COLLECTION.orderBy("enterTime", "desc").get()
            const orders = []
            snapshot.forEach(doc => {
                orders.push(doc.data())
            })
            setOrders(orders)
            setOrdersLoading(false)
        }

        getOrders()

        dispatch(orderAction({
            _id: '',
            amountTotal: '',
            amountExteriorVehicleWashing: '',
            amountInternalVehicleWashing: '',
            donePerson: '',
            doneTime: '',
            enterPerson: '',
            enterTime: '',
            exteriorVehicleWashingPaid: '',
            exteriorVehicleWashing: '',
            internalVehicleWashing: '',
            methodOfPayment: '',
            note: '',
            vehicleColor: '',
            vehiclePlates: '',
            vehicleType: ''
        }))

        document.body.style = 'background: #F4F4F4'

    }, [])

    const ordersGroups = _.chain(orders)
        .groupBy(group => group.enterTime && moment(group.enterTime.toDate()).format('DD.MM.YYYY'))
        .map((value, key) => ({ enterTime: key, data: value }))
        .value()

    const filterSupercards = ordersGroups.filter(item => item?.enterTime.toLowerCase().includes(searchValueHistory.toLowerCase()) ? true : false)

    return (
        <div>
            <Layout none>
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Link to='/'>
                                <IconCancel className='history__icon-cancel' />
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='history__title'>
                                Istorija
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <input className='history__input' placeholder='Pretraga' type='search' value={searchValueHistory} onChange={(e) => dispatch(historyAction({ searchValue: e.target.value }))} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {
                                !ordersLoading
                                    ?
                                    filterSupercards
                                        .map(a => {
                                            return (
                                                <CardHistory

                                                    key={a.enterTime}

                                                    day='Datum'
                                                    dateAndMonth={a.enterTime}

                                                    exteriorVehicleWashing={a.data.filter(b => b.exteriorVehicleWashing !== 'NE').length}
                                                    internalVehicleWashing={a.data.filter(b => b.internalVehicleWashing !== 'NE').length}

                                                    exteriorVehicleWashingCar={a.data.filter(b => b.vehicleType === 'AUTO/SUV/VAN' && b.exteriorVehicleWashing !== 'NE').length}
                                                    exteriorVehicleWashingVan={a.data.filter(b => b.vehicleType === 'KOMBI' && b.exteriorVehicleWashing !== 'NE').length}
                                                    exteriorVehicleWashingTruck={a.data.filter(b => b.vehicleType === 'KAMION' && b.exteriorVehicleWashing !== 'NE').length}

                                                    internalVehicleWashingCar={a.data.filter(b => b.vehicleType === 'AUTO/SUV/VAN' && b.internalVehicleWashing !== 'NE').length}
                                                    internalVehicleWashingVan={a.data.filter(b => b.vehicleType === 'KOMBI' && b.internalVehicleWashing !== 'NE').length}
                                                    internalVehicleWashingTruck={a.data.filter(b => b.vehicleType === 'KAMION' && b.internalVehicleWashing !== 'NE').length}

                                                    exteriorVehicleWashingCash={a.data.filter(b => b.exteriorVehicleWashing !== 'NE' && b.methodOfPayment === 'GOTOVINA').reduce((c, d) => c = c + d.amountExteriorVehicleWashing, 0)}
                                                    exteriorVehicleWashingSupercard={a.data.filter(b => b.exteriorVehicleWashing !== 'NE' && b.methodOfPayment === 'SUPERKARTICA').reduce((c, d) => c = c + d.amountExteriorVehicleWashing, 0)}
                                                    exteriorVehicleWashingGyral={a.data.filter(b => b.exteriorVehicleWashing !== 'NE' && b.methodOfPayment === 'ŽIRALNO').reduce((c, d) => c = c + d.amountExteriorVehicleWashing, 0)}
                                                    exteriorVehicleWashingTotal={a.data.filter(b => b.exteriorVehicleWashing !== 'NE' && b.methodOfPayment).reduce((c, d) => c = c + d.amountExteriorVehicleWashing, 0)}

                                                    internalVehicleWashingCash={a.data.filter(b => b.internalVehicleWashing !== 'NE' && b.methodOfPayment === 'GOTOVINA').reduce((c, d) => c = c + d.amountInternalVehicleWashing, 0)}
                                                    internalVehicleWashingSupercard={a.data.filter(b => b.internalVehicleWashing !== 'NE' && b.methodOfPayment === 'SUPERKARTICA').reduce((c, d) => c = c + d.amountInternalVehicleWashing, 0)}
                                                    internalVehicleWashingGyral={a.data.filter(b => b.internalVehicleWashing !== 'NE' && b.methodOfPayment === 'ŽIRALNO').reduce((c, d) => c = c + d.amountInternalVehicleWashing, 0)}
                                                    internalVehicleWashingTotal={a.data.filter(b => b.internalVehicleWashing !== 'NE' && b.methodOfPayment).reduce((c, d) => c = c + d.amountInternalVehicleWashing, 0)}

                                                    checkoutCondition={a.data.filter(b =>

                                                        b.exteriorVehicleWashingPaid === 'NE'
                                                        // && b.internalVehicleWashing !== 'NE'
                                                        && b.methodOfPayment === 'GOTOVINA'
                                                        // && b.exteriorVehicleWashing !== 'NE'

                                                    ).reduce((c, d) => c = c + d.amountTotal, 0)}


                                                    checkoutCharged={a.data.filter(b =>

                                                        b.exteriorVehicleWashingPaid === 'NE'
                                                        // && b.internalVehicleWashing !== 'NE'
                                                        && b.methodOfPayment === 'GOTOVINA'
                                                        // && b.exteriorVehicleWashing !== 'NE'
                                                        && b.doneTime

                                                    ).reduce((c, d) => c = c + d.amountTotal, 0)}

                                                    terminalCondition={a.data.filter(b =>

                                                        b.exteriorVehicleWashingPaid === 'NE'
                                                        // && b.internalVehicleWashing !== 'NE'
                                                        && b.methodOfPayment === 'SUPERKARTICA'
                                                        // && b.exteriorVehicleWashing !== 'NE'

                                                    ).reduce((c, d) => c = c + d.amountTotal, 0)}

                                                    terminalCharged={a.data.filter(b =>

                                                        b.exteriorVehicleWashingPaid === 'NE'
                                                        // && b.internalVehicleWashing !== 'NE'
                                                        && b.methodOfPayment === 'SUPERKARTICA'
                                                        // && b.exteriorVehicleWashing !== 'NE'
                                                        && b.doneTime

                                                    ).reduce((c, d) => c = c + d.amountTotal, 0)}

                                                    terminalStateCash={a.data.filter(b => b.vehicleType === 'AUTO/SUV/VAN' && b.exteriorVehicleWashingPaid !== 'NE' && b.methodOfPayment === 'GOTOVINA').reduce((c, d) => c = c + d.amountTotal, 0)}
                                                    terminalStateNumber={a.data.filter(b => b.vehicleType === 'AUTO/SUV/VAN' && b.exteriorVehicleWashingPaid !== 'NE' && b.methodOfPayment === 'GOTOVINA').length}
                                                />

                                            )
                                        })
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </div >
    )
}

export default History
