import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import './Navbar.style.css'

import { ReactComponent as Logo } from './assets/logo.svg'
import { ReactComponent as IconCard } from './assets/icon-card.svg'
import { ReactComponent as IconCardActive } from './assets/icon-card-active.svg'
import { ReactComponent as IconHistory } from './assets/icon-history.svg'
import { ReactComponent as IconHistoryActive } from './assets/icon-history-active.svg'
import { ReactComponent as IconSearch } from './assets/icon-search.svg'
import { ReactComponent as IconSearchActive } from './assets/icon-search-active.svg'
import { ReactComponent as IconAdd } from './assets/icon-add.svg'
import { ReactComponent as IconAddActive } from './assets/icon-add-active.svg'

const Navbar = () => {

    const location = useLocation()

    return (
        <>
            <div className='navbar'>
                <Link to='/'>
                    <Logo className='navbar__logo' />
                </Link>
                <div className='navbar__wrapper-icons'>
                    {location.pathname === '/supercard' ? <Link to='/'><IconCardActive className='navbar__card' /></Link> : <Link to='/supercard'><IconCard className='navbar__card' /></Link>}
                    {location.pathname === '/history' ? <Link to='/'><IconHistoryActive className='navbar__user' /></Link> : <Link to='/history'><IconHistory className='navbar__user' /></Link>}
                    {location.pathname === '/search' ? <Link to='/'><IconSearchActive className='navbar__search' /></Link> : <Link to='/search'><IconSearch className='navbar__search' /></Link>}
                    {location.pathname === '/order' ? <Link to='/'><IconAddActive className='navbar__add' /></Link> : <Link to='/order'><IconAdd className='navbar__add' /></Link>}
                </div>
            </div>
        </>
    )
}

export default Navbar
